<template>
  <v-card
    class="pa-4"
    outlined
    :class="value._destroy ? '_destroy-color' : ''"
  >
    <v-btn
      :ripple="false"
      icon
      fab
      class="remove-btn"
      @click="clickRemoveBtn(referringHistoryTempIdx)"
    >
      <v-icon>
        {{ icons.mdiCloseBox }}
      </v-icon>
    </v-btn>

    <v-select
      v-if="users.length > 0"
      v-model="userId"
      :items="items"
      item-value="id"
      :menu-props="{ auto: true, offsetY: true }"
      :error="!value.userId"
      :append-icon="icons.mdiHeartOutline"
      hide-details
      hide-selected
      no-data-text="選択可能なデータがありません"
      :disabled="!!value.id"
    >
      <template #selection="{ item }">
        <template v-if="item.attributes">
          <v-badge
            dot
            left
            overlap
            avatar
            offset-x="10"
            offset-y="10"
            :color="badgeColor(item)"
          >
            <v-avatar
              size="32"
              class="mr-2"
              left
            >
              <v-img
                v-if="item.attributes.profileImage"
                :src="item.attributes.profileImage"
              />
              <v-icon v-else>
                {{ icons.mdiAccountCircleOutline }}
              </v-icon>
            </v-avatar>
          </v-badge>
          {{ item.attributes.name }}
        </template>
      </template>

      <template #item="{ item }">
        <template v-if="!item.attributes">
          <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
          <v-list-item-content v-text="item.header" />
        </template>

        <template v-else>
          <v-badge
            dot
            left
            overlap
            avatar
            offset-x="24"
            offset-y="16"
            :color="badgeColor(item)"
          >
            <v-list-item-avatar size="32">
              <v-img
                v-if="item.attributes.profileImage"
                :src="item.attributes.profileImage"
              />
              <v-icon v-else>
                {{ icons.mdiAccountCircleOutline }}
              </v-icon>
            </v-list-item-avatar>
          </v-badge>

          <v-list-item-content>
            <v-list-item-title>
              {{ item.attributes.name }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-select>

    <v-chip-group
      v-if="referrals.length > 0"
      v-model="referralId"
      class="mt-3"
      active-class="v-chip-light-bg primary--text"
      column
      mandatory
    >
      <v-chip
        v-for="(referral, referralIdx) in referrals"
        :key="`referrals[${referralIdx}]`"
        :value="referral.id"
        :disabled="!!value.id && referral.id !== value.referralId"
        small
        outlined
        filter
      >
        {{ referral.attributes.name }}
      </v-chip>
    </v-chip-group>
  </v-card>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { computed, inject } from '@vue/composition-api'
import {
  each,
  groupBy,
  map,
  filter,
  includes,
} from 'lodash'
import {
  mdiAccountCircleOutline,
  mdiSelection,
  mdiHeartOutline,
  mdiCloseBox,
  mdiClose,
  mdiCashMarker,
} from '@mdi/js'

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true,
    },
    referringHistoryTempIdx: {
      type: Number,
      required: true,
    },
    users: {
      type: Array,
      default: () => [],
    },
    referrals: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const userId = useVModel(props.value, 'userId')
    const referralId = useVModel(props.value, 'referralId')
    // eslint-disable-next-line no-underscore-dangle
    const _destroy = useVModel(props.value, '_destroy')

    const roleMsg = {
      alliance: 'アライアンス',
      cast: 'キャスト',
      waiter: 'ボーイ',
    }

    const usersWithTimecard = inject('usersWithTimecard', [])

    const items = computed(() => {
      const lists = []
      const roleOrder = ['alliance', 'cast', 'waiter']
      const usersGrouped = groupBy(props.users, 'attributes.role')
      const workingUsersGrouped = groupBy(usersWithTimecard.value, 'attributes.role')

      each(roleOrder, role => {
        if (!usersGrouped[role]) return

        lists.push({ header: roleMsg[role] })

        const whichUsers = workingUsersGrouped[role] && workingUsersGrouped[role].length > 0
          ? workingUsersGrouped[role]
          : usersGrouped[role]

        lists.push(...whichUsers)
      })

      return lists
    })

    const badgeColor = computed(() => userItem => {
      const { timecard } = userItem
      if (!timecard) return 'transparent'

      return timecard.leaveAt ? 'secondary' : 'success'
    })

    const clickRemoveBtn = index => {
      if (!props.value.id) {
        emit('remove', index)

        return
      }

      if (_destroy.value) {
        _destroy.value = false
      } else {
        emit('remove', index)
      }
    }

    return {
      // data
      userId,
      referralId,
      roleMsg,

      // computed
      items,
      badgeColor,

      // methods
      clickRemoveBtn,

      icons: {
        mdiAccountCircleOutline,
        mdiSelection,
        mdiHeartOutline,
        mdiCloseBox,
        mdiClose,
        mdiCashMarker,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.remove-btn {
  position: absolute;
  top: -8px;
  right: -8px;
}

._destroy-color {
  opacity: 0.5;
  filter: hue-rotate(30deg);
}
</style>

<template>
  <div class="hr-user-earning-daily-summaries">
    <v-data-table
      :id="`user-${userId}-earning-daily-summaries`"
      :key="`${headers.map(o => o.value).join('')}${JSON.stringify(earningSummaries)}`"
      :loading="isLoading"
      :items="earningSummaries"
      :headers="headers"
      :header-props="{ sortByText: 'ソート' }"
      class="text-no-wrap"
      hide-default-footer
      disable-pagination
      no-data-text="データがありません"
      fixed-header
      height="80vh"
    >
      <template #loading>
        <v-skeleton-loader type="table" />
      </template>

      <template #top>
        <div class="w-full d-flex">
          <v-spacer />
          <v-btn
            :loading="isLoading"
            :disabled="earningSummaries.length <= 0 || isLoading"
            :ripple="false"
            color="secondary"
            small
            class="ma-2"
            @click="download"
          >
            <v-icon left>
              {{ icons.mdiFileExcel }}
            </v-icon>
            ダウンロード
          </v-btn>
        </div>
      </template>

      <template
        v-if="!$vuetify.breakpoint.xsOnly && Object.values(headerColSpanSize).reduce((a, b) => a + b) > headerColSpanSize.staticSize"
        #header
      >
        <tr>
          <!-- date -->
          <th
            colspan="1"
            class="v-data-table__divider fixed-cell"
          />
          <!-- 総支給, 日払, 残給 -->
          <th
            :colspan="headerColSpanSize.staticSize - 1"
            class="v-data-table__divider"
          />
          <th
            v-if="headerColSpanSize.wageSize > 0"
            :colspan="headerColSpanSize.wageSize"
            class="ps-4 py-1 v-data-table__divider"
            align="left"
          >
            <small class="secondary--text">基本給</small>
          </th>
          <th
            v-if="headerColSpanSize.rebateSystemUnit > 0"
            :colspan="headerColSpanSize.rebateSystemUnit"
            class="ps-4 py-1 v-data-table__divider"
            align="left"
          >
            <small class="secondary--text">バック</small>
          </th>
          <th
            v-if="headerColSpanSize.orphan > 0"
            :colspan="headerColSpanSize.orphan"
            class="ps-4 py-1 v-data-table__divider"
            align="left"
          >
            <small class="secondary--text">その他/調整</small>
          </th>
          <th
            v-if="headerColSpanSize.paySystemUnit > 0"
            :colspan="headerColSpanSize.paySystemUnit"
            class="ps-4 py-1"
            align="left"
          >
            <small class="secondary--text">変動給</small>
          </th>
        </tr>
      </template>

      <template
        v-for="header in headers"
        #[`item.${header.value}`]="{item}"
      >
        <span :key="`item${header.value}-user${item.userId}`">
          <span v-if="header.value === 'date'">
            {{ item.date }}
          </span>

          <span v-else-if="header.value === 'workingDuration'">
            {{ item.workingDuration }}
          </span>

          <span
            v-else
            :class="{ 'error--text': +item[header.value] < 0 }"
          >
            {{ item[header.value] ? `¥${Math.abs(item[header.value]).toLocaleString()}` : '' }}
          </span>
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/composition-api'
import { each, update, find } from 'lodash'
import { intervalToDuration } from 'date-fns'
import { mdiFileExcel } from '@mdi/js'
import * as XLSX from 'xlsx/xlsx.mjs'
import EarningSummaryApi from '@/api/admin/EarningSummary'
import WorkingResultSummaryApi from '@/api/admin/WorkingResultSummary'
import useCompInit from '@/views/composable/useCompInit'
import usePaymentUserDailyListDataTable from '@/views/composable/data-table/usePaymentUserDailyListDataTable'

export default {
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
    endDate: {
      type: String,
      default: '',
    },
    userId: {
      type: Number,
      required: true,
      default: 0,
    },
    userName: {
      type: String,
      default: '',
    },
    wageEarningHeaders: {
      type: Array,
      default: () => [],
    },
    rebateSystemUnitEarningHeaders: {
      type: Array,
      default: () => [],
    },
    orphanEarningHeaders: {
      type: Array,
      default: () => [],
    },
    paySystemUnitEarningHeaders: {
      type: Array,
      default: () => [],
    },
    targetTimeText: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const { isLoading, initWith } = useCompInit()
    const earningSummaries = ref([])
    const workingResultSummaries = ref([])
    const {
      wageEarningHeaders,
      rebateSystemUnitEarningHeaders,
      orphanEarningHeaders,
      paySystemUnitEarningHeaders,
    } = toRefs(props)

    const { headers, headerColSpanSize, buildXlsxData } = usePaymentUserDailyListDataTable({
      earningSummaries,
      wageEarningHeaders,
      rebateSystemUnitEarningHeaders,
      orphanEarningHeaders,
      paySystemUnitEarningHeaders,
    })

    const getUserEarningDailySummaries = async () => {
      const res = await EarningSummaryApi.getUserEarningDailySummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        userId: props.userId,
      })

      if (res?.data) {
        earningSummaries.value = [...res.data.earningSummaries]
      }
    }

    const getUserWorkingResultDailySummaries = async () => {
      const res = await WorkingResultSummaryApi.getUserWorkingResultDailySummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        userId: props.userId,
      })

      if (res?.data) {
        workingResultSummaries.value = [...res.data.workingResultSummaries]

        each(workingResultSummaries.value, workingResultSummary => {
          if (!workingResultSummary.workingDuration) return

          const earningSummary = find(earningSummaries.value, o => o.date === workingResultSummary.date)
          if (!earningSummary) return

          const avgHourlyWage = earningSummary.wageEarningAmount / (workingResultSummary.workingDuration / 3600)

          const duration = intervalToDuration({ start: 0, end: +workingResultSummary.workingDuration * 1000 })
          const hours = `${((duration.days * 24) + duration.hours)}`.padStart(2, '0')
          const minutes = `${duration.minutes}`.padStart(2, '0')

          update(earningSummary, 'workingDuration', () => `${hours}:${minutes}`)
          update(earningSummary, 'workingDays', () => workingResultSummary.workingDays)
          update(earningSummary, 'avgHourlyWage', () => avgHourlyWage)
        })
      }
    }

    const download = () => {
      const paymentUserDailyListExcel = buildXlsxData()
      const wb = XLSX.utils.book_new()
      const data = XLSX.utils.json_to_sheet(paymentUserDailyListExcel)
      XLSX.utils.book_append_sheet(wb, data, props.userName)
      XLSX.writeFile(wb, `給料_日次_${props.targetTimeText}_${props.userName}.xlsx`)
    }

    initWith([
      getUserEarningDailySummaries().then(() => getUserWorkingResultDailySummaries()),
    ])

    return {
      // data
      isLoading,
      earningSummaries,

      // computed
      headers,
      headerColSpanSize,

      // methods
      download,

      icons: {
        mdiFileExcel,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

@include theme--child(hr-user-earning-daily-summaries) using ($material) {
  th.fixed-cell,
  td.fixed-cell {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
    background: map-deep-get($material, 'app-bar');
  }

  th.fixed-cell {
    z-index: 4;
  }

  .v-progress-linear {
    z-index: 5;
  }
}
</style>

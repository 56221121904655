<!-- eslint-disable vue/no-unused-vars -->
<!-- eslint-disable vue/require-v-for-key -->
<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <v-btn
      :loading="loading || downloading"
      :disabled="earningSummaries.length <= 0 || loading || downloading"
      color="primary"
      :ripple="false"
      @click="download"
    >
      <v-icon left>{{ icons.mdiDownload }}</v-icon>
      報酬支払明細書
    </v-btn>

    <table
      v-show="false"
      id="payment-papers-table"
      :key="`paymentPapers-${paymentPapers.length}`"
    >
      <tr>
        <td
          v-for="n in paymentPapers.length"
          :key="`報酬支払明細書[${n}]`"
          colspan="3"
        >
          報酬支払明細書
        </td>
      </tr>

      <tr>
        <td
          v-for="n in paymentPapers.length"
          :key="`targetTimeText[${n}]`"
          colspan="3"
        >
          {{ targetTimeText }}
        </td>
      </tr>

      <tr>
        <td
          v-for="n in paymentPapers.length"
          :key="`currentClub.name[${n}]`"
          colspan="3"
        >
          {{ currentClub.name }}
        </td>
      </tr>

      <tr>
        <td
          v-for="n in paymentPapers.length"
          :key="`paymentPaper.userName[${n}]`"
          colspan="3"
        >
          {{ paymentPapers[n - 1] && paymentPapers[n - 1].userName }}様
        </td>
      </tr>

      <tr>
        <template
          v-for="n in paymentPapers.length"
        >
          <td
            :key="`勤務日数-${n}-${paymentPapers[n - 1].userName}-key`"
            colspan="2"
          >
            勤務日数
          </td>
          <td :key="`勤務日数-${n}-${paymentPapers[n - 1].userName}-value`">{{ paymentPapers[n - 1] && paymentPapers[n - 1].workingDays }}</td>
        </template>
      </tr>

      <tr>
        <template
          v-for="n in paymentPapers.length"
        >
          <td
            :key="`勤務時間-${n}-${paymentPapers[n - 1].userName}-key`"
            colspan="2"
          >
            勤務時間
          </td>
          <td :key="`勤務時間-${n}-${paymentPapers[n - 1].userName}-value`">{{ paymentPapers[n - 1] && paymentPapers[n - 1].workingDuration }}</td>
        </template>
      </tr>

      <tr>
        <template
          v-for="n in paymentPapers.length"
        >
          <td
            :key="`支給-${n}-${paymentPapers[n - 1].userName}-key`"
            :rowspan="minRowsLength + 2"
            valign="top"
          >
            支給
          </td>
          <td :key="`支給-${n}-${paymentPapers[n - 1].userName}-key-blank-1`"></td>
          <td :key="`支給-${n}-${paymentPapers[n - 1].userName}-key-blank-2`"></td>
        </template>
      </tr>

      <template
        v-for="rowN in minRowsLength"
      >
        <tr
          :key="`addition-row-number-${rowN}`"
        >
          <template
            v-for="n in paymentPapers.length"
          >
            <template v-if="paymentPapers[n - 1] && paymentPapers[n - 1].additions && paymentPapers[n - 1].additions[rowN - 1]">
              <td :key="`addition-row-${n}-${paymentPapers[n - 1].userName}-key`">{{ paymentPapers[n - 1].additions[rowN - 1].columnName }}</td>
              <td :key="`addition-row-${n}-${paymentPapers[n - 1].userName}-value`">{{ paymentPapers[n - 1].additions[rowN - 1].amount }}</td>
            </template>
            <template v-else>
              <td :key="`addition-row-${n}-${paymentPapers[n - 1].userName}-blank-1`"><span class="ma-1"></span></td>
              <td :key="`addition-row-${n}-${paymentPapers[n - 1].userName}-blank-2`"><span class="ma-1"></span></td>
            </template>
          </template>
        </tr>
      </template>

      <tr>
        <template
          v-for="n in paymentPapers.length"
        >
          <td :key="`addition-合計-${n}-${paymentPapers[n - 1].userName}-key`">合計</td>
          <td :key="`addition-合計-${n}-${paymentPapers[n - 1].userName}-value`">
            <span v-if="paymentPapers[n - 1] && paymentPapers[n - 1].additions && paymentPapers[n - 1].additions.length > 0">
              {{ paymentPapers[n - 1].additions.reduce((acc, obj) => acc + obj.amount, 0) }}
            </span>
            <span v-else>0</span>
          </td>
        </template>
      </tr>

      <tr>
        <template
          v-for="n in paymentPapers.length"
        >
          <td
            :key="`控除-${n}-${paymentPapers[n - 1].userName}-key`"
            :rowspan="minRowsLength + 2"
            valign="top"
          >
            控除
          </td>
          <td :key="`控除-${n}-${paymentPapers[n - 1].userName}-key-blank-1`"></td>
          <td :key="`控除-${n}-${paymentPapers[n - 1].userName}-key-blank-2`"></td>
        </template>
      </tr>

      <template
        v-for="rowN in minRowsLength"
      >
        <tr
          :key="`deduction-row-number-${rowN}`"
        >
          <template
            v-for="n in paymentPapers.length"
          >
            <template v-if="paymentPapers[n - 1] && paymentPapers[n - 1].deductions && paymentPapers[n - 1].deductions[rowN - 1]">
              <td :key="`deduction-row-${n}-${paymentPapers[n - 1].userName}-key`">{{ paymentPapers[n - 1].deductions[rowN - 1].columnName }}</td>
              <td :key="`deduction-row-${n}-${paymentPapers[n - 1].userName}-value`">{{ Math.abs(paymentPapers[n - 1].deductions[rowN - 1].amount) }}</td>
            </template>
            <template v-else>
              <td :key="`deduction-row-${n}-${paymentPapers[n - 1].userName}-blank-1`"><span class="ma-1"></span></td>
              <td :key="`deduction-row-${n}-${paymentPapers[n - 1].userName}-blank-2`"><span class="ma-1"></span></td>
            </template>
          </template>
        </tr>
      </template>

      <tr>
        <template
          v-for="n in paymentPapers.length"
        >
          <td :key="`deduction-合計-${n}-${paymentPapers[n - 1].userName}-key`">合計</td>
          <td :key="`deduction-合計-${n}-${paymentPapers[n - 1].userName}-value`">
            <span v-if="paymentPapers[n - 1] && paymentPapers[n - 1].deductions && paymentPapers[n - 1].deductions.length > 0">
              {{ Math.abs(paymentPapers[n - 1].deductions.reduce((acc, obj) => acc + obj.amount, 0)) }}
            </span>
            <span v-else>0</span>
          </td>
        </template>
      </tr>

      <tr>
        <template
          v-for="n in paymentPapers.length"
        >
          <td
            :key="`日払-${n}-${paymentPapers[n - 1].userName}-key`"
            colspan="2"
          >
            日払
          </td>
          <td
            v-if="paymentPapers[n - 1]"
            :key="`日払-${n}-${paymentPapers[n - 1].userName}-value`"
          >
            {{ paymentPapers[n - 1].beforehandEarningAmount }}
          </td>
        </template>
      </tr>

      <tr>
        <template
          v-for="n in paymentPapers.length"
        >
          <td
            :key="`差引支給額-${n}-${paymentPapers[n - 1].userName}-key`"
            colspan="2"
          >
            差引支給額
          </td>
          <td
            v-if="paymentPapers[n - 1]"
            :key="`差引支給額-${n}-${paymentPapers[n - 1].userName}-value`"
          >
            {{ paymentPapers[n - 1].remainingEarningAmount }}
          </td>
        </template>
      </tr>
    </table>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {
  chain,
  each,
  find,
  includes,
  map,
  flatMap,
  times,
} from 'lodash'
import { mdiDownload } from '@mdi/js'
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    earningSummaries: {
      type: Array,
      required: true,
      default: () => [],
    },
    rebateSystemUnitEarningHeaders: {
      type: Array,
      default: () => [],
    },
    orphanEarningHeaders: {
      type: Array,
      default: () => [],
    },
    paySystemUnitEarningHeaders: {
      type: Array,
      default: () => [],
    },
    targetTimeText: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const downloading = ref(false)

    const dynamicEarningHeaders = computed(() => {
      return [
        ...props.rebateSystemUnitEarningHeaders,
        ...props.orphanEarningHeaders,
        ...props.paySystemUnitEarningHeaders,
      ]
    })

    const paymentPapers = computed(() => {
      const targetKeys = map([
        ...props.rebateSystemUnitEarningHeaders,
        ...props.orphanEarningHeaders,
        ...props.paySystemUnitEarningHeaders,
      ], 'value')

      return chain(props.earningSummaries)
        .map(earningSummary => {
          const additions = [] // 支給
          const deductions = [] // 控除

          // 基本給
          if (+earningSummary.wageEarningAmount > 0) {
            additions.push({ columnName: '基本給', amount: +earningSummary.wageEarningAmount })
          }

          // バック, 変動給, その他でプラスのものは支給、マイナスは控除に
          each(earningSummary, (value, key) => {
            if (includes(targetKeys, key)) {
              const columnName = find(dynamicEarningHeaders.value, o => o.value === key)?.text
              const obj = { columnName, amount: +value }

              // eslint-disable-next-line no-unused-expressions
              Number(value) >= 0 ? additions.push(obj) : deductions.push(obj)
            }
          })

          // // 日払(日払いは支給のケツに)
          // if (+earningSummary.beforehandEarningAmount > 0) {
          //   deductions.push({ columnName: '日払', amount: +earningSummary.beforehandEarningAmount * -1 })
          // }

          const {
            userName,
            remainingEarningAmount,
            workingDays,
            workingDuration,
            beforehandEarningAmount,
          } = earningSummary

          return {
            userName,
            remainingEarningAmount,
            workingDays,
            workingDuration,
            additions,
            deductions,
            beforehandEarningAmount,
          }
        })
        .value()
    })

    const minRowsLength = computed(() => {
      const additionMaxRows = chain(paymentPapers.value).map(o => o.additions.length).max().value()
      const deductionMaxRows = chain(paymentPapers.value).map(o => o.deductions.length).max().value()

      return Math.max(Math.max(+additionMaxRows, +deductionMaxRows), 5) || 5
    })

    const download = () => {
      const tableCloned = document.getElementById('payment-papers-table').cloneNode(true)

      const ws = XLSX.utils.table_to_sheet(tableCloned)
      const wscols = flatMap(paymentPapers.value, o => {
        const arr = o.additions.concat(o.deductions)

        return [
          { width: 5 },
          { width: chain(arr).map(t => +t.columnName?.length).max().value() * 2 },
          { width: chain(arr).map(t => +t.amount?.toString()?.length).max().value() * 2 },
        ]
      })
      const wsrows = times(tableCloned.querySelectorAll('tr').length, () => { return { hpt: 22 } })
      ws['!cols'] = wscols
      ws['!rows'] = wsrows

      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, '支払報酬明細書')
      XLSX.writeFile(wb, '支払報酬明細書.xlsx')
    }

    return {
      // data
      downloading,

      // computed
      paymentPapers,
      minRowsLength,

      // methods
      download,

      icons: {
        mdiDownload,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
table, th, td {
  border: 1px;
  border-style: solid;
  border-collapse: collapse;
  white-space: nowrap;
}
</style>

<template>
  <v-select
    v-model="idAndType"
    :items="items"
    item-text="attributes.name"
    item-disabled="header"
    :item-value="item => item.attributes && [item.id, item.attributes.vendibleType]"
    :menu-props="{ auto: true, offsetY: true }"
    :prepend-inner-icon="icons.mdiSelection"
    clearable
    hide-details
    hide-selected
    no-data-text="選択可能なデータがありません"
    @change="onSelect"
    @click:clear="onClear"
  >
    <template #append-outer>
      <v-btn
        icon
        small
        :ripple="false"
        :color="isNonExistVendible ? 'primary' : ''"
        @click="onIsNonExist"
      >
        <v-icon
          :color="isNonExistVendible ? 'primary' : ''"
        >
          {{ icons.mdiEmoticonCoolOutline }}
        </v-icon>
      </v-btn>
    </template>

    <template #selection="{ item }">
      <span v-if="item.attributes">
        {{ item.attributes.name }}
      </span>
    </template>

    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.attributes ? item.attributes.name : item.header }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { mdiSelection, mdiEmoticonCoolOutline, mdiClose } from '@mdi/js'
import { each, groupBy } from 'lodash'
import useCurrentData from '@/views/composable/useCurrentData'
import useQueryString from '@/views/composable/useQueryString'

export default {
  props: {
    vendibles: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { currentClub } = useCurrentData()
    const { route, addQuery, removeQuery } = useQueryString()

    const idAndType = ref([route.value.query.vendibleId, route.value.query.vendibleType])
    const isNonExistVendible = ref(route.value.query.isNonExistVendible === 'true')

    const query = computed(() => {
      return {
        vendibleId: idAndType.value && idAndType.value[0],
        vendibleType: idAndType.value && idAndType.value[1],
        isNonExistVendible: isNonExistVendible.value,
      }
    })

    const items = computed(() => {
      const lists = []
      const types = {
        Course: currentClub.value.courseAlias,
        Nomination: currentClub.value.nominationAlias,
        Item: 'アイテム',
      }
      const vendiblesGrouped = groupBy(props.vendibles, 'attributes.vendibleType')

      each(types, (header, type) => {
        if (!vendiblesGrouped[type]) return

        lists.push({ header })
        lists.push(...vendiblesGrouped[type])
      })

      return lists
    })

    const onSelect = () => {
      isNonExistVendible.value = false

      addQuery(query.value)

      emit('selected', query.value)
    }

    const onIsNonExist = () => {
      isNonExistVendible.value = !isNonExistVendible.value
      idAndType.value = []

      addQuery(query.value)

      emit('selected', query.value)
    }

    const onClear = () => {
      idAndType.value = []
      isNonExistVendible.value = false

      removeQuery(['vendibleId', 'vendibleType', 'isNonExistVendible'])

      emit('cleared')
    }

    return {
      // data
      idAndType,
      isNonExistVendible,

      // computed
      items,

      // methods
      onSelect,
      onIsNonExist,
      onClear,

      icons: {
        mdiSelection,
        mdiEmoticonCoolOutline,
        mdiClose,
      },
    }
  },
}
</script>

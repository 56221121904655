<template>
  <v-select
    v-model="userId"
    :items="items"
    item-text="attributes.name"
    item-disabled="header"
    item-value="id"
    :menu-props="{ auto: true, offsetY: true }"
    :prepend-inner-icon="prependInnerIcon"
    :prepend-icon="prependIcon"
    hide-details
    hide-selected
    :label="label"
    :placeholder="placeholder"
    :clearable="clearable"
    no-data-text="選択可能なデータがありません"
    @change="onSelect"
    @click:clear="onClear"
  >
    <template #selection="{ item }">
      <span v-if="item.attributes">
        {{ item.attributes.name }}
      </span>
    </template>

    <template #item="{ item }">
      <template v-if="!item.attributes">
        <v-list-item-content v-text="item.header" />
      </template>

      <template v-else>
        <v-list-item-avatar size="32">
          <v-img
            v-if="item.attributes.profileImage"
            :src="item.attributes.profileImage"
          />
          <v-icon v-else>
            {{ icons.mdiAccountCircleOutline }}
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.attributes.name }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-select>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { mdiHeartOutline, mdiAccountCircleOutline } from '@mdi/js'
import { each, groupBy } from 'lodash'
import useQueryString from '@/views/composable/useQueryString'

export default {
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    userIdKeyName: {
      type: String,
      default: 'userId',
    },
    roleOrder: {
      type: Array,
      default: () => ['cast', 'waiter', 'alliance'],
    },
    prependIcon: {
      type: String,
      default: '',
    },
    prependInnerIcon: {
      type: String,
      default: () => mdiHeartOutline,
    },
    withQuery: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    selectedUserId: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { route, addQuery, removeQuery } = useQueryString()

    const userId = ref(props.selectedUserId?.toString() || route.value.query[props.userIdKeyName])

    const query = computed(() => {
      const obj = {}
      obj[props.userIdKeyName] = userId.value

      return obj
    })

    const emitValue = computed(() => {
      return props.withQuery ? query.value : userId.value
    })

    const items = computed(() => {
      const roleMsg = {
        cast: 'キャスト',
        waiter: 'ボーイ',
        alliance: 'アライアンス',
      }
      const lists = []
      const recipientsGrouped = groupBy(props.users, 'attributes.role')

      each(props.roleOrder, role => {
        if (!recipientsGrouped[role]) return

        lists.push({ header: roleMsg[role] })
        lists.push(...recipientsGrouped[role])
      })

      return lists
    })

    const onSelect = () => {
      if (props.withQuery) addQuery(query.value)

      emit('selected', emitValue.value)
    }

    const onClear = () => {
      userId.value = null

      if (props.withQuery) removeQuery('userId')

      emit('cleared')
    }

    return {
      // data
      userId,

      // computed
      items,

      // methods
      onSelect,
      onClear,

      icons: {
        mdiHeartOutline,
        mdiAccountCircleOutline,
      },
    }
  },
}
</script>

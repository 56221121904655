<template>
  <div>
    <v-btn
      :loading="isLoading"
      :disabled="isLoading"
      :ripple="false"
      color="secondary"
      class="ma-2"
      @click="download"
    >
      <v-icon left>
        {{ icons.mdiFileExcel }}
      </v-icon>
      すべてのXLSXをダウンロードする
    </v-btn>

    <p
      v-show="isLoading"
      class="ml-4 secondary--text"
    >
      ダウンロードには時間がかかります
    </p>
  </div>
</template>

<script>
import { mdiFileExcel } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { each, find, update } from 'lodash'
import { intervalToDuration } from 'date-fns'
import * as XLSX from 'xlsx/xlsx.mjs'
import FinanceApi from '@/api/admin/Finance'
import RealMoneyProfitApi from '@/api/admin/RealMoneyProfit'
import ExpenseApi from '@/api/admin/Expense'
import BenefitApi from '@/api/admin/Benefit'
import TableSummaryApi from '@/api/admin/TableSummary'
import EarningSummaryApi from '@/api/admin/EarningSummary'
import WorkingResultSummaryApi from '@/api/admin/WorkingResultSummary'
import RebateSystemUnitApi from '@/api/admin/RebateSystemUnit'
import PaySystemUnitApi from '@/api/admin/PaySystemUnit'
import UserSalesSummaryApi from '@/api/admin/UserSalesSummary'
import UserContributionRatioSummaryApi from '@/api/admin/UserContributionRatioSummary'
import UserVendingSummaryApi from '@/api/admin/UserVendingSummary'
import UserReferringSummaryApi from '@/api/admin/UserReferringSummary'
import UserVendingPointSummaryApi from '@/api/admin/UserVendingPointSummary'
import TableApi from '@/api/waiter/Table'
import ReferringHistoryApi from '@/api/waiter/ReferringHistory'
import DataExportVendingHistoryApi from '@/api/v2/data-export/VendingHistory'
import ReceiptApi from '@/api/waiter/Receipt'
import BalanceReceivableApi from '@/api/waiter/BalanceReceivable'
import TimecardApi from '@/api/waiter/Timecard'
import CashRegisterTransactionApi from '@/api/waiter/CashRegisterTransaction'
import CashRegisterTransactionSummaryApi from '@/api/waiter/CashRegisterTransactionSummary'
import useCurrentData from '@/views/composable/useCurrentData'
import useCompInit from '@/views/composable/useCompInit'
import useBusinessResultDetailListDataTable from '@/views/composable/data-table/useBusinessResultDetailListDataTable'
import useBusinessResultMoneyDetailListDataTable from '@/views/composable/data-table/useBusinessResultMoneyDetailListDataTable'
import useExpensesAndBenefitsDataTable from '@/views/composable/data-table/useExpensesAndBenefitsDataTable'
import useTableResultOverviewsDataTable from '@/views/composable/data-table/useTableResultOverviewsDataTable'
import usePaymentsDataTable from '@/views/composable/data-table/usePaymentsDataTable'
import useKpiDataTable from '@/views/composable/data-table/useKpiDataTable'
import useTablesDataTable from '@/views/composable/data-table/useTablesDataTable'
import useReferringHistoriesDataTable from '@/views/composable/data-table/useReferringHistoriesDataTable'
import useVendingHistoriesDataTable from '@/views/composable/data-table/useVendingHistoriesDataTable'
import useReceiptsDataTable from '@/views/composable/data-table/useReceiptsDataTable'
import useBalanceReceivablesDataTable from '@/views/composable/data-table/useBalanceReceivablesDataTable'
import useTimecardsDataTable from '@/views/composable/data-table/useTimecardsDataTable'
import useCashRegisterTransactionsDataTable from '@/views/composable/data-table/useCashRegisterTransactionsDataTable'
import useCashRegisterTransactionSummariesDataTable from '@/views/composable/data-table/useCashRegisterTransactionSummariesDataTable'

export default {
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const role = ref('cast')

    const incomeDetailList = ref([])
    const spendingDetailList = ref([])
    const moneyIncomeDetailList = ref([])
    const moneySpendingDetailList = ref([])
    const realMoneyProfits = ref([])
    const expenses = ref([])
    const benefits = ref([])
    const tableResultOverviews = ref([])
    const dynamicTableResultOverviewHeaders = ref([])
    const earningSummaries = ref([])
    const workingResultSummaries = ref([])
    const rebateSystemUnits = ref([])
    const paySystemUnits = ref([])
    const userSalesSummaries = ref([])
    const userContributionRatioSummaries = ref([])
    const userVendingSummaries = ref([])
    const userReferringSummaries = ref([])
    const userVendingPointSummaries = ref([])
    const userSalesSummaryHeaders = ref([])
    const userContributionRatioSummaryHeaders = ref([])
    const userVendingSummaryHeaders = ref([])
    const userReferringSummaryHeaders = ref([])
    const userVendingPointSummaryHeaders = ref([])
    const tables = ref([])
    const referringHistories = ref([])
    const vendingHistories = ref([])
    const receipts = ref([])
    const balanceReceivables = ref([])
    const timecards = ref([])
    const cashRegisterTransactions = ref([])
    const cashRegisterTransactionSummaries = ref([])

    const isDaily = computed(() => {
      return +props.interval === 0 && !props.endDate
    })

    const { currentClub } = useCurrentData()

    const { isLoading, initWith } = useCompInit()

    const businessResultDetailListDataTable = useBusinessResultDetailListDataTable({ incomeDetailList, spendingDetailList })
    const businessResultMoneyDetailListDataTable = useBusinessResultMoneyDetailListDataTable({ moneyIncomeDetailList, moneySpendingDetailList, realMoneyProfits })
    const expensesAndBenefitsDataTable = useExpensesAndBenefitsDataTable({ expenses, benefits })
    const tableResultOverviewsDataTable = useTableResultOverviewsDataTable({ tableResultOverviews, dynamicTableResultOverviewHeaders })
    const paymentsDataTable = usePaymentsDataTable({
      earningSummaries,
      rebateSystemUnits,
      paySystemUnits,
      role,
      isDaily,
    })
    const kpiDataTable = useKpiDataTable({
      userSalesSummaries,
      userContributionRatioSummaries,
      userVendingSummaries,
      userReferringSummaries,
      userVendingPointSummaries,
      userSalesSummaryHeaders,
      userContributionRatioSummaryHeaders,
      userVendingSummaryHeaders,
      userReferringSummaryHeaders,
      userVendingPointSummaryHeaders,
      role,
    })
    const tablesDataTable = useTablesDataTable(tables)
    const referringHistoriesDataTable = useReferringHistoriesDataTable(referringHistories)
    const vendingHistoriesDataTable = useVendingHistoriesDataTable(vendingHistories)
    const receiptsDataTable = useReceiptsDataTable(receipts)
    const balanceReceivablesDataTable = useBalanceReceivablesDataTable(balanceReceivables)
    const timecardsDataTable = useTimecardsDataTable(timecards)
    const {
      buildAllTransactionsXlsxData,
      buildDepositTransactionsXlsxData,
      buildWithdrawTransactionsXlsxData,
    } = useCashRegisterTransactionsDataTable(cashRegisterTransactions)
    const cashRegisterTxSummariesDataTable = useCashRegisterTransactionSummariesDataTable(cashRegisterTransactionSummaries)

    const getIncomeDetailList = async () => {
      const res = await FinanceApi.getIncomeDetailList({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        isDailyListBetweenIntervalRange: true,
      })

      if (res?.status === 200) {
        incomeDetailList.value = res.data.incomeDetailList
      }
    }

    const getSpendingDetailList = async () => {
      const res = await FinanceApi.getSpendingDetailList({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        isDailyListBetweenIntervalRange: true,
      })

      if (res?.status === 200) {
        spendingDetailList.value = res.data.spendingDetailList
      }
    }

    const getMoneyIncomeDetailList = async () => {
      const res = await FinanceApi.getMoneyIncomeDetailList({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        isDailyListBetweenIntervalRange: true,
      })

      if (res?.status === 200) {
        moneyIncomeDetailList.value = res.data.moneyIncomeDetailList
      }
    }

    const getMoneySpendingDetailList = async () => {
      const res = await FinanceApi.getMoneySpendingDetailList({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        isDailyListBetweenIntervalRange: true,
      })

      if (res?.status === 200) {
        moneySpendingDetailList.value = res.data.moneySpendingDetailList
      }
    }

    const getRealMoneyProfits = async () => {
      const res = await RealMoneyProfitApi.getRealMoneyProfits({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        realMoneyProfits.value = res.data.realMoneyProfits.data
      }
    }

    const getBenefits = async () => {
      const res = await BenefitApi.getBenefits({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        benefits.value = res.data.benefits.data
      }
    }

    const getExpenses = async () => {
      const res = await ExpenseApi.getExpenses({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        expenses.value = res.data.expenses.data
      }
    }

    const getTableResultOverviews = async () => {
      const res = await TableSummaryApi.getOverviews({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        tableResultOverviews.value = res.data.overviews
        dynamicTableResultOverviewHeaders.value = res.data.dynamicHeaders
      }
    }

    const getEarningSummaries = async () => {
      const res = await EarningSummaryApi.getEarningSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) earningSummaries.value = [...res.data.earningSummaries]
    }

    const getWorkingResultSummaries = async () => {
      const res = await WorkingResultSummaryApi.getWorkingResultSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        workingResultSummaries.value = [...res.data.workingResultSummaries]

        each(workingResultSummaries.value, workingResultSummary => {
          if (!workingResultSummary.workingDuration) return

          const earningSummary = find(earningSummaries.value, o => +o.userId === +workingResultSummary.userId)
          if (!earningSummary) return

          const avgHourlyWage = earningSummary.wageEarningAmount / (workingResultSummary.workingDuration / 3600)

          const duration = intervalToDuration({ start: 0, end: +workingResultSummary.workingDuration * 1000 })
          const hours = `${((duration.days * 24) + duration.hours)}`.padStart(2, '0')
          const minutes = `${duration.minutes}`.padStart(2, '0')

          update(earningSummary, 'workingDuration', () => `${hours}:${minutes}`)
          update(earningSummary, 'workingDays', () => workingResultSummary.workingDays)
          update(earningSummary, 'avgHourlyWage', () => avgHourlyWage)
        })
      }
    }

    const getRebateSystemUnits = async () => {
      const res = await RebateSystemUnitApi.getRebateSystemUnits()

      if (res?.data) rebateSystemUnits.value = [...res.data.rebateSystemUnits.data]
    }

    const getPaySystemUnits = async () => {
      const res = await PaySystemUnitApi.getPaySystemUnits()

      if (res?.data) paySystemUnits.value = [...res.data.paySystemUnits.data]
    }

    const getUserSalesSummaries = async () => {
      const res = await UserSalesSummaryApi.getUserSalesSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        userSalesSummaries.value = [...res.data.userSalesSummaries]
        userSalesSummaryHeaders.value = [...res.data.headers]
      }
    }
    const getUserContributionRatioSummaries = async () => {
      const res = await UserContributionRatioSummaryApi.getUserContributionRatioSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        userContributionRatioSummaries.value = [...res.data.userContributionRatioSummaries]
        userContributionRatioSummaryHeaders.value = [...res.data.headers]
      }
    }
    const getUserVendingSummaries = async () => {
      const res = await UserVendingSummaryApi.getUserVendingSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        userVendingSummaries.value = [...res.data.userVendingSummaries]
        userVendingSummaryHeaders.value = [...res.data.headers]
      }
    }
    const getUserReferringSummaries = async () => {
      const res = await UserReferringSummaryApi.getUserReferringSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        userReferringSummaries.value = [...res.data.userReferringSummaries]
        userReferringSummaryHeaders.value = [...res.data.headers]
      }
    }

    const getUserVendingPointSummaries = async () => {
      const res = await UserVendingPointSummaryApi.getUserVendingPointSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        userVendingPointSummaries.value = [...res.data.userVendingPointSummaries]
        userVendingPointSummaryHeaders.value = [...res.data.headers]
      }
    }

    const getTables = async () => {
      const res = await TableApi.getTables({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        tables.value = [...res.data.tables.data]
      }
    }

    const getReferringHistories = async () => {
      const res = await ReferringHistoryApi.getReferringHistories({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        referringHistories.value = [...res.data.referringHistories.data]
      }
    }

    const getVendingHistories = async () => {
      const res = await DataExportVendingHistoryApi.getVendingHistories({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data?.data) {
        vendingHistories.value = [...res.data.data]
      }
    }

    const getReceipts = async () => {
      const res = await ReceiptApi.getReceipts({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        receipts.value = [...res.data.receipts.data]
      }
    }

    const getBalanceReceivables = async () => {
      const res = await BalanceReceivableApi.getBalanceReceivables({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        balanceReceivables.value = [...res.data.balanceReceivables.data]
      }
    }

    const getTimecards = async () => {
      const res = await TimecardApi.getTimecards({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        timecards.value = [...res.data.timecards.data]
      }
    }

    const getCashRegisterTransactions = async (params = null) => {
      const res = await CashRegisterTransactionApi.getCashRegisterTransactions({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        cashRegisterTransactions.value = [...res.data.cashRegisterTransactions.data]
      }
    }

    const getCashRegisterTransactionSummaries = async (params = null) => {
      const res = await CashRegisterTransactionSummaryApi.getCashRegisterTransactionSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        cashRegisterTransactionSummaries.value = [...res.data.cashRegisterTransactionSummaries]
      }
    }

    const writeXlsx = () => {
      role.value = 'cast'
      const castPaymentsData = paymentsDataTable.buildXlsxData()
      const castPaymentsWs = XLSX.utils.json_to_sheet(castPaymentsData)
      XLSX.utils.sheet_add_aoa(castPaymentsWs, [paymentsDataTable.buildXlsxSecondaryHeaders()], { origin: 1 })
      const castKpiData = kpiDataTable.buildXlsxData()

      role.value = 'waiter'
      const waiterPaymentsData = paymentsDataTable.buildXlsxData()
      const waiterPaymentsWs = XLSX.utils.json_to_sheet(waiterPaymentsData)
      XLSX.utils.sheet_add_aoa(waiterPaymentsWs, [paymentsDataTable.buildXlsxSecondaryHeaders()], { origin: 1 })
      const waiterKpiData = kpiDataTable.buildXlsxData()

      role.value = 'alliance'
      const alliancePaymentsData = paymentsDataTable.buildXlsxData()
      const alliancePaymentsWs = XLSX.utils.json_to_sheet(alliancePaymentsData)
      XLSX.utils.sheet_add_aoa(alliancePaymentsWs, [paymentsDataTable.buildXlsxSecondaryHeaders()], { origin: 1 })
      const allianceKpiData = kpiDataTable.buildXlsxData()

      const sheets = [
        { name: '収支(発生)', data: businessResultDetailListDataTable.buildXlsxData() },
        { name: '収支(現金)', data: businessResultMoneyDetailListDataTable.buildXlsxData() },
        { name: '諸入費', data: expensesAndBenefitsDataTable.buildXlsxData() },
        { name: '卓概要(リスト)', data: tableResultOverviewsDataTable.buildXlsxData() },
        { name: '給料(キャスト)', data: castPaymentsData, ws: castPaymentsWs },
        { name: '給料(ボーイ)', data: waiterPaymentsData, ws: waiterPaymentsWs },
        { name: '給料(アライアンス)', data: alliancePaymentsData, ws: alliancePaymentsWs },
        { name: 'KPI(キャスト)', data: castKpiData },
        { name: 'KPI(ボーイ)', data: waiterKpiData },
        { name: 'KPI(アライアンス)', data: allianceKpiData },
        { name: '卓', data: tablesDataTable.buildXlsxData() },
        { name: `${currentClub.value?.referralAlias || 'リファラル'}履歴`, data: referringHistoriesDataTable.buildXlsxData() },
        { name: '販売履歴', data: vendingHistoriesDataTable.buildXlsxData() },
        { name: '伝票', data: receiptsDataTable.buildXlsxData() },
        { name: '売掛', data: balanceReceivablesDataTable.buildXlsxData() },
        { name: 'タイムカード', data: timecardsDataTable.buildXlsxData() },
        { name: 'レジ金Tx(サマリ)', data: cashRegisterTxSummariesDataTable.buildXlsxData() },
        { name: 'レジ金Tx(ALL)', data: buildAllTransactionsXlsxData() },
        { name: 'レジ金Tx(入金)', data: buildDepositTransactionsXlsxData() },
        { name: 'レジ金Tx(出金)', data: buildWithdrawTransactionsXlsxData() },
      ]

      const wb = XLSX.utils.book_new()
      each(sheets, sheet => {
        const ws = sheet.ws ? sheet.ws : XLSX.utils.json_to_sheet(sheet.data)
        XLSX.utils.book_append_sheet(wb, ws, sheet.name)
      })

      let title = `${props.date}_${['日', '週', '月'][props.interval]}`
      if (props.endDate) title = `${props.date}~${props.endDate}`
      XLSX.writeFile(wb, `YONAREZI_データ_${title}.xlsx`)
    }

    const download = () => {
      initWith([
        getSpendingDetailList(),
        getMoneyIncomeDetailList(),
        getMoneySpendingDetailList(),
        getRealMoneyProfits(),
        getBenefits(),
        getExpenses(),
        getTableResultOverviews(),
        getIncomeDetailList(),
        getEarningSummaries().then(() => getWorkingResultSummaries()),
        getRebateSystemUnits(),
        getPaySystemUnits(),
        getUserSalesSummaries(),
        getUserContributionRatioSummaries(),
        getUserVendingSummaries(),
        getUserReferringSummaries(),
        getUserVendingPointSummaries(),
        getTables(),
        getReferringHistories(),
        getVendingHistories(),
        getReceipts(),
        getBalanceReceivables(),
        getTimecards(),
        getCashRegisterTransactions(),
        getCashRegisterTransactionSummaries(),
      ]).then(() => {
        writeXlsx()
      })
    }

    return {
      isLoading,
      download,
      icons: {
        mdiFileExcel,
      },
    }
  },
}
</script>

<template>
  <div>
    <v-edit-dialog
      ref="dialog"
      :persistent="persistent"
      @open="open"
      @close="close"
    >
      <span class="display-flex justify-center align-center">
        <v-text-field
          v-if="displayName"
          ref="textField"
          :value="displayName"
          :prepend-icon="prependIcon"
          :append-icon="appendIcon"
          :append-outer-icon="appendOuterIcon"
          :prefix="prefix"
          :suffix="suffix"
          readonly
          hide-details
          :disabled="disabled"
          :style="textFieldStyle"
          class="flex shrink"
        />

        <slot name="append-outer-display-name" />
      </span>

      <template #input>
        <v-card
          elevation="0"
          :style="{ maxHeight: `${maxHeight}px` }"
        >
          <v-card-title v-if="title">
            {{ title }}
          </v-card-title>

          <v-card-text>
            <slot name="input" />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :ripple="false"
              :color="btnColor"
              :disabled="!isValid"
              @click="save"
            >
              {{ saveText }}
            </v-btn>

            <v-btn
              :ripple="false"
              color="blue darken-1"
              text
              @click="close"
            >
              キャンセル
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { mdiWindowClose } from '@mdi/js'
import { getVuetify } from '@/@core/utils'

export default {
  props: {
    displayName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    saveText: {
      type: String,
      default: '更新',
    },
    btnColor: {
      type: String,
      default: 'primary',
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    appendOuterIcon: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: Number,
      default: 400,
    },
  },
  setup(props, { emit }) {
    const $vuetify = getVuetify()

    const dialog = ref()
    const textField = ref()

    const rootThemeClass = computed(() => {
      return $vuetify.theme.isDark ? 'theme--dark' : 'theme--light'
    })

    const textFieldStyle = computed(() => {
      const style = { maxWidth: 'auto' }
      if (!(props.displayName && textField.value?.$el)) return style

      const letterCount = textField.value.$el.querySelector('.v-text-field__slot input').value.length

      style.maxWidth = `${letterCount * 16}px`

      return style
    })

    const open = () => {
      emit('open')
    }

    const close = () => {
      dialog.value.$data.isActive = false

      emit('close')
    }

    const save = () => {
      emit('save')
      close()
    }

    return {
      // data
      dialog,
      textField,

      // computed
      rootThemeClass,
      textFieldStyle,

      // methods
      open,
      close,
      save,

      icons: {
        mdiWindowClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-input {
  margin: 0;
  padding: 0;
}
</style>

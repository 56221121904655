<template>
  <div class="hr-user-kept-daily-summaries">
    <v-data-table
      :id="`user-${userId}-kpi-daily-summaries`"
      :key="`${headers.map(o => o.value).join('')}${JSON.stringify(items)}`"
      :loading="isLoading"
      :items="items"
      :headers="headers"
      :header-props="{ sortByText: 'ソート' }"
      class="text-no-wrap"
      hide-default-footer
      disable-pagination
      no-data-text="データがありません"
      fixed-header
      height="80vh"
    >
      <template #loading>
        <v-skeleton-loader type="table" />
      </template>

      <template #top>
        <div class="w-full d-flex">
          <v-spacer />
          <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            :ripple="false"
            color="secondary"
            small
            class="ma-2"
            @click="download"
          >
            <v-icon left>
              {{ icons.mdiFileExcel }}
            </v-icon>
            ダウンロード
          </v-btn>
        </div>
      </template>

      <template
        v-for="salesHeader in (userSalesSummaryHeaders || [])"
        #[`item.${salesHeader.value}`]="{item}"
      >
        {{ item[salesHeader.value] ? `¥${item[salesHeader.value].toLocaleString()}` : null }}
      </template>

      <template
        v-for="contributionRatioHeader in (userContributionRatioSummaryHeaders || [])"
        #[`item.${contributionRatioHeader.value}`]="{item}"
      >
        {{ item[contributionRatioHeader.value] ? `${item[contributionRatioHeader.value]}%` : null }}
      </template>

      <template
        v-for="vendingPointHeader in (userVendingPointSummaryHeaders || [])"
        #[`item.${vendingPointHeader.value}`]="{item}"
      >
        {{ item[vendingPointHeader.value] ? `${item[vendingPointHeader.value]}pt` : null }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/composition-api'
import { mdiFileExcel } from '@mdi/js'
import * as XLSX from 'xlsx/xlsx.mjs'
import UserSalesSummaryApi from '@/api/admin/UserSalesSummary'
import UserContributionRatioSummaryApi from '@/api/admin/UserContributionRatioSummary'
import UserVendingSummaryApi from '@/api/admin/UserVendingSummary'
import UserReferringSummaryApi from '@/api/admin/UserReferringSummary'
import UserVendingPointSummaryApi from '@/api/admin/UserVendingPointSummary'
import useCompInit from '@/views/composable/useCompInit'
import useKpiUserDailyListDataTable from '@/views/composable/data-table/useKpiUserDailyListDataTable'

export default {
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
    endDate: {
      type: String,
      default: '',
    },
    userId: {
      type: Number,
      required: true,
      default: 0,
    },
    userName: {
      type: String,
      default: '',
    },
    userSalesSummaryHeaders: {
      type: Array,
      default: () => [],
    },
    userContributionRatioSummaryHeaders: {
      type: Array,
      default: () => [],
    },
    userVendingSummaryHeaders: {
      type: Array,
      default: () => [],
    },
    userReferringSummaryHeaders: {
      type: Array,
      default: () => [],
    },
    userVendingPointSummaryHeaders: {
      type: Array,
      default: () => [],
    },
    targetTimeText: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const { isLoading, initWith } = useCompInit()
    const userSalesSummaries = ref([])
    const userContributionRatioSummaries = ref([])
    const userVendingSummaries = ref([])
    const userReferringSummaries = ref([])
    const userVendingPointSummaries = ref([])

    const {
      userSalesSummaryHeaders,
      userContributionRatioSummaryHeaders,
      userVendingSummaryHeaders,
      userReferringSummaryHeaders,
      userVendingPointSummaryHeaders,
    } = toRefs(props)

    const { items, headers, buildXlsxData } = useKpiUserDailyListDataTable({
      userSalesSummaries,
      userContributionRatioSummaries,
      userVendingSummaries,
      userReferringSummaries,
      userVendingPointSummaries,
      userSalesSummaryHeaders,
      userContributionRatioSummaryHeaders,
      userVendingSummaryHeaders,
      userReferringSummaryHeaders,
      userVendingPointSummaryHeaders,
    })

    const getUserSalesDailySummaries = async () => {
      const res = await UserSalesSummaryApi.getUserSalesDailySummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        userId: props.userId,
      })

      if (res?.data) {
        userSalesSummaries.value = [...res.data.userSalesSummaries]
      }
    }
    const getUserContributionRatioDailySummaries = async () => {
      const res = await UserContributionRatioSummaryApi.getUserContributionRatioDailySummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        userId: props.userId,
      })

      if (res?.data) {
        userContributionRatioSummaries.value = [...res.data.userContributionRatioSummaries]
      }
    }
    const getUserVendingDailySummaries = async () => {
      const res = await UserVendingSummaryApi.getUserVendingDailySummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        userId: props.userId,
      })

      if (res?.data) {
        userVendingSummaries.value = [...res.data.userVendingSummaries]
      }
    }
    const getUserReferringDailySummaries = async () => {
      const res = await UserReferringSummaryApi.getUserReferringDailySummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        userId: props.userId,
      })

      if (res?.data) {
        userReferringSummaries.value = [...res.data.userReferringSummaries]
      }
    }
    const getUserVendingPointDailySummaries = async () => {
      const res = await UserVendingPointSummaryApi.getUserVendingPointDailySummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        userId: props.userId,
      })

      if (res?.data) {
        userVendingPointSummaries.value = [...res.data.userVendingPointSummaries]
      }
    }

    const download = () => {
      const kpiUserDailyListExcel = buildXlsxData()
      const wb = XLSX.utils.book_new()
      const data = XLSX.utils.json_to_sheet(kpiUserDailyListExcel)
      XLSX.utils.book_append_sheet(wb, data, props.userName)
      XLSX.writeFile(wb, `KPI_日次_${props.targetTimeText}_${props.userName}.xlsx`)
    }

    initWith([
      getUserSalesDailySummaries(),
      getUserContributionRatioDailySummaries(),
      getUserVendingDailySummaries(),
      getUserReferringDailySummaries(),
      getUserVendingPointDailySummaries(),
    ])

    return {
      // data
      isLoading,

      // computed
      items,
      headers,

      // methods
      download,

      icons: {
        mdiFileExcel,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

@include theme--child(hr-user-kept-daily-summaries) using ($material) {
  th.fixed-cell,
  td.fixed-cell {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
    background: map-deep-get($material, 'app-bar');
  }

  th.fixed-cell {
    z-index: 4;
  }

  .v-progress-linear {
    z-index: 5;
  }
}
</style>

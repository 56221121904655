<template>
  <div class="pb-15 hr-management-payments">
    <v-tabs
      v-model="currentTabIdx"
      show-arrows
      style="background: transparent"
    >
      <v-tab
        v-for="role in roles"
        :key="`tab[${role.value}]`"
      >
        {{ role.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentTabIdx"
      touchless
      style="background: transparent"
    >
      <v-tab-item
        v-for="role in roles"
        :key="`tabItems[${role.value}]`"
      >
        <transition name="slideRight">
          <v-row
            v-if="selected.length > 0"
            style="animation-duration: 0.3s"
          >
            <v-col class="px-4 py-6 d-flex">
              <v-spacer />

              <payment-excel
                :key="selected.length + Math.random()"
                :loading="isLoading"
                :earning-summaries="selected"
                :wage-earning-headers="wageEarningHeaders"
                :rebate-system-unit-earning-headers="rebateSystemUnitEarningHeaders"
                :orphan-earning-headers="orphanEarningHeaders"
                :pay-system-unit-earning-headers="paySystemUnitEarningHeaders"
                :target-time-text="targetTimeText"
                @downloaded="selected = []"
              />
            </v-col>
          </v-row>
        </transition>

        <!-- eslint-disable vue/no-deprecated-v-bind-sync -->
        <v-data-table
          id="earning-summaries-table"
          :key="`${headers.map(o => o.value).join('')}${JSON.stringify(items)}`"
          ref="dataTable"
          v-model="selected"
          :expanded.sync="expanded"
          :loading="isLoading"
          :items="items"
          :headers="headers"
          :header-props="{ sortByText: 'ソート' }"
          item-key="userId"
          class="text-no-wrap"
          hide-default-footer
          disable-pagination
          show-select
          no-data-text="データがありません"
          sort-by="userId"
          single-expand
          :show-expand="!isDaily"
          :fixed-header="!isInViewport"
          :height="isInViewport ? 'auto' : '70vh'"
        >
          <template #loading>
            <v-skeleton-loader type="table" />
          </template>

          <template #top>
            <div class="w-full d-flex">
              <v-spacer />
              <v-btn
                :loading="isLoading"
                :disabled="items.length <= 0 || isLoading"
                :ripple="false"
                color="secondary"
                small
                class="ma-2"
                @click="download"
              >
                <v-icon left>
                  {{ icons.mdiFileExcel }}
                </v-icon>
                ダウンロード
              </v-btn>
            </div>
          </template>

          <template
            v-if="!$vuetify.breakpoint.xsOnly && Object.values(headerColSpanSize).reduce((a, b) => a + b) > headerColSpanSize.staticSize"
            #header
          >
            <tr>
              <!-- select -->
              <th colspan="1" />
              <!-- expand -->
              <th
                v-if="!isDaily"
                colspan="1"
              />
              <!-- userName -->
              <th
                colspan="1"
                class="v-data-table__divider fixed-cell"
              />
              <!-- 総支給, 日払, 残給 -->
              <th
                :colspan="headerColSpanSize.staticSize - 1"
                class="v-data-table__divider"
              />
              <th
                v-if="headerColSpanSize.wageSize > 0"
                :colspan="headerColSpanSize.wageSize"
                class="ps-4 py-1 v-data-table__divider"
                align="left"
              >
                <small class="secondary--text">基本給</small>
              </th>
              <th
                v-if="headerColSpanSize.rebateSystemUnit > 0"
                :colspan="headerColSpanSize.rebateSystemUnit"
                class="ps-4 py-1 v-data-table__divider"
                align="left"
              >
                <small class="secondary--text">バック</small>
              </th>
              <th
                v-if="headerColSpanSize.orphan > 0"
                :colspan="headerColSpanSize.orphan"
                class="ps-4 py-1 v-data-table__divider"
                align="left"
              >
                <small class="secondary--text">その他/調整</small>
              </th>
              <th
                v-if="headerColSpanSize.paySystemUnit > 0"
                :colspan="headerColSpanSize.paySystemUnit"
                class="ps-4 py-1"
                align="left"
              >
                <small class="secondary--text">変動給</small>
              </th>
            </tr>
          </template>

          <template #[`body.prepend`]="{headers: _headers}">
            <tr>
              <td
                v-for="header in _headers"
                :key="`${header.value}-sum`"
                :class="{
                  'fixed-cell': header.cellClass === 'fixed-cell',
                  'v-data-table__divider': header.divider,
                }"
              >
                <span :class="{ 'error--text': Number(accRow[header.text]) && Number(accRow[header.text]) < 0 }">
                  {{ Number(accRow[header.text]) ? `¥${Math.abs(accRow[header.text]).toLocaleString()}` : accRow[header.text] }}
                </span>
              </td>
            </tr>
          </template>

          <template
            v-for="header in headers"
            #[`item.${header.value}`]="{item}"
          >
            <span :key="`item${header.value}-user${item.userId}`">
              <span v-if="header.value === 'userName'">
                {{ item.userName }}
              </span>
              <span v-else-if="header.value === 'workingDays'">
                {{ item.workingDays }}
              </span>
              <span v-else-if="header.value === 'workingDuration'">
                {{ item.workingDuration }}
              </span>

              <span
                v-else
                :class="{ 'error--text': +item[header.value] < 0 }"
              >
                {{ item[header.value] ? `¥${Math.abs(item[header.value]).toLocaleString()}` : '' }}
              </span>
            </span>
          </template>

          <template #expanded-item="{item}">
            <v-dialog
              v-if="expanded.length > 0"
              :value="expanded[0].userId === item.userId"
              transition="dialog-bottom-transition"
              fullscreen
              persistent
            >
              <v-card
                tile
                class="h-full"
              >
                <v-app-bar>
                  <v-app-bar-title>
                    {{ item.userName }}
                    <span class="mr-4">日次</span>
                  </v-app-bar-title>
                  <v-spacer />
                  <v-app-bar-nav-icon>
                    <v-btn
                      fab
                      icon
                      large
                      :ripple="false"
                      @click="expanded = []"
                    >
                      <v-icon>
                        {{ icons.mdiWindowClose }}
                      </v-icon>
                    </v-btn>
                  </v-app-bar-nav-icon>
                </v-app-bar>

                <v-card-text class="pt-4">
                  <user-daily-list
                    v-if="!isDaily"
                    :date="date"
                    :interval="interval"
                    :end-date="endDate"
                    :user-id="+item.userId"
                    :user-name="item.userName"
                    :wage-earning-headers="wageEarningHeaders"
                    :rebate-system-unit-earning-headers="rebateSystemUnitEarningHeaders"
                    :orphan-earning-headers="orphanEarningHeaders"
                    :pay-system-unit-earning-headers="paySystemUnitEarningHeaders"
                    :target-time-text="targetTimeText"
                  />
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {
  find,
  each,
  update,
  times,
} from 'lodash'
import { intervalToDuration } from 'date-fns'
import { mdiFileExcel, mdiWindowClose } from '@mdi/js'
import * as XLSX from 'xlsx/xlsx.mjs'
import EarningSummaryApi from '@/api/admin/EarningSummary'
import WorkingResultSummaryApi from '@/api/admin/WorkingResultSummary'
import RebateSystemUnitApi from '@/api/admin/RebateSystemUnit'
import PaySystemUnitApi from '@/api/admin/PaySystemUnit'
import useCompInit from '@/views/composable/useCompInit'
import usePaymentsDataTable from '@/views/composable/data-table/usePaymentsDataTable'
import UserDailyList from '@/views/components/hr/payments/UserDailyList.vue'
import PaymentExcel from '@/views/components/hr/payments/PaymentExcel.vue'

export default {
  components: {
    UserDailyList,
    PaymentExcel,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
    endDate: {
      type: String,
      default: '',
    },
    targetTimeText: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const { isLoading, initWith } = useCompInit()
    const isInViewport = ref(true)
    const dataTable = ref()
    const currentTabIdx = ref(0)
    const earningSummaries = ref([])
    const rebateSystemUnits = ref([])
    const paySystemUnits = ref([])
    const workingResultSummaries = ref([])
    const expanded = ref([])
    const selected = ref([])
    const roles = [
      { text: 'キャスト', value: 'cast' },
      { text: 'ボーイ', value: 'waiter' },
      { text: 'アライアンス', value: 'alliance' },
    ]

    const currentRole = computed(() => roles[currentTabIdx.value].value)

    const isDaily = computed(() => {
      return +props.interval === 0 && !props.endDate
    })

    const {
      rebateSystemUnitEarningHeaders,
      paySystemUnitEarningHeaders,
      orphanEarningHeaders,
      wageEarningHeaders,
      headers,
      headerColSpanSize,
      items,
      accRow,
      buildXlsxData,
    } = usePaymentsDataTable({
      earningSummaries,
      rebateSystemUnits,
      paySystemUnits,
      role: currentRole,
      isDaily,
    })

    const getEarningSummaries = async () => {
      const res = await EarningSummaryApi.getEarningSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) earningSummaries.value = [...res.data.earningSummaries]
    }

    const getWorkingResultSummaries = async () => {
      const res = await WorkingResultSummaryApi.getWorkingResultSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        workingResultSummaries.value = [...res.data.workingResultSummaries]

        each(workingResultSummaries.value, workingResultSummary => {
          if (!workingResultSummary.workingDuration) return

          const earningSummary = find(earningSummaries.value, o => +o.userId === +workingResultSummary.userId)
          if (!earningSummary) return

          const avgHourlyWage = earningSummary.wageEarningAmount / (workingResultSummary.workingDuration / 3600)

          const duration = intervalToDuration({ start: 0, end: +workingResultSummary.workingDuration * 1000 })
          const hours = `${((duration.days * 24) + duration.hours)}`.padStart(2, '0')
          const minutes = `${duration.minutes}`.padStart(2, '0')

          update(earningSummary, 'workingDuration', () => `${hours}:${minutes}`)
          update(earningSummary, 'workingDays', () => workingResultSummary.workingDays)
          update(earningSummary, 'avgHourlyWage', () => avgHourlyWage)
        })
      }
    }

    const getRebateSystemUnits = async () => {
      const res = await RebateSystemUnitApi.getRebateSystemUnits()

      if (res?.data) rebateSystemUnits.value = [...res.data.rebateSystemUnits.data]
    }

    const getPaySystemUnits = async () => {
      const res = await PaySystemUnitApi.getPaySystemUnits()

      if (res?.data) paySystemUnits.value = [...res.data.paySystemUnits.data]
    }

    const download = () => {
      const data = buildXlsxData()

      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(data)
      XLSX.utils.sheet_add_aoa(ws, [
        [
          ...(headerColSpanSize.value.staticSize ? times(headerColSpanSize.value.staticSize, () => null) : []),
          ...(headerColSpanSize.value.wageSize ? ['基本給', ...times(headerColSpanSize.value.wageSize - 1, () => null)] : []),
          ...(headerColSpanSize.value.rebateSystemUnit ? ['バック', ...times(headerColSpanSize.value.rebateSystemUnit - 1, () => null)] : []),
          ...(headerColSpanSize.value.orphan ? ['その他/調整', ...times(headerColSpanSize.value.orphan - 1, () => null)] : []),
          ...(headerColSpanSize.value.paySystemUnit ? ['変動給', ...times(headerColSpanSize.value.paySystemUnit - 1, () => null)] : []),
        ],
      ], { origin: 1 })

      XLSX.utils.book_append_sheet(wb, ws, '給料')
      XLSX.writeFile(wb, `給料_${props.targetTimeText}.xlsx`)
    }

    initWith([
      getEarningSummaries().then(() => getWorkingResultSummaries()),
      getRebateSystemUnits(),
      getPaySystemUnits(),
    ]).then(() => {
      isInViewport.value = true

      const rect = (dataTable.value || [])[0]?.$el?.getBoundingClientRect()
      if (!rect) return

      isInViewport.value = (
        rect.top >= 0
          && rect.left >= 0
          && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    })

    return {
      // data
      dataTable,
      isInViewport,
      currentTabIdx,
      isLoading,
      rebateSystemUnits,
      paySystemUnits,
      expanded,
      selected,
      roles,

      // computed
      isDaily,
      headers,
      items,
      headerColSpanSize,
      wageEarningHeaders,
      rebateSystemUnitEarningHeaders,
      orphanEarningHeaders,
      paySystemUnitEarningHeaders,
      accRow,

      // methods
      download,

      icons: {
        mdiFileExcel,
        mdiWindowClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

@include theme--child(v-data-table__expanded__content) using ($material) {
  td {
    background-color: map-deep-get($material, 'background');
  }
}

.v-data-table__expanded__content td {
  width: 100vw;
  vertical-align: top;

  &>div:first-child {
    padding: 12px 0 32px 0;

    &>.col {
      background-color: rgba(0,0,0,.05);
    }
  }
}
</style>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

@include theme--child(hr-management-payments) using ($material) {
  th.fixed-cell,
  td.fixed-cell {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
    background: map-deep-get($material, 'app-bar');
  }

  th.fixed-cell {
    z-index: 4;
  }

  .v-progress-linear {
    z-index: 5;
  }
}
</style>

<template>
  <div>
    <v-select
      :value="customerTableTaggingsAttributes"
      :items="customerTags"
      chips
      multiple
      solo
      hide-details
      hide-selected
      dense
      return-object
      :menu-props="{ auto: true, offsetY: true }"
      :prepend-inner-icon="icons.mdiPound"
      :loading="isLoading"
      :item-value="getCustomerTagId"
      no-data-text="選択可能なデータがありません"
      @change="select($event)"
    >
      <template #selection="{ attrs, item }">
        <v-chip
          v-bind="attrs"
          small
          close
          :color="isToDestroy(item.id) ? 'error' : ''"
          :class="isToDestroy(item.id) ? 'v-chip-light-bg error--text' : ''"
          @click:close="remove(item)"
        >
          {{ item.attributes.name }}
        </v-chip>
      </template>

      <template #item="{ item }">
        <v-list-item-action>
          <v-checkbox
            hide-details
            disabled
            :ripple="false"
          />
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.attributes.name }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>
  </div>
</template>

<script>
import { ref, computed, onUnmounted } from '@vue/composition-api'
import { mdiPound } from '@mdi/js'
import {
  filter,
  each,
  findIndex,
  find,
  includes,
  map,
} from 'lodash'
import CustomerTagApi from '@/api/waiter/CustomerTag'
import TableApi from '@/api/waiter/Table'

export default {
  props: {
    tableId: {
      type: [Number, String],
      default: null,
      validator: value => !Number.isNaN(Number(value)),
    },
    customerTableTaggings: {
      type: Array,
      default: () => [],
    },
    updateEveryTime: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(false)
    const customerTags = ref([])
    const customerTableTaggingsAttributes = ref([])

    const customerTagIdsToDestroy = computed(() => {
      const destroyTargets = filter(customerTableTaggingsAttributes.value, '_destroy')

      return map(destroyTargets, o => Number(o.customerTagId))
    })

    const isToDestroy = customerTagId => {
      return includes(customerTagIdsToDestroy.value, Number(customerTagId))
    }

    const buildCustomerTableTaggingAttributes = ({
      id = null,
      customerTagId,
      tableId = props.tableId || null,
      _destroy = false,
    }) => {
      return {
        id,
        customerTagId,
        tableId,
        _destroy,
      }
    }

    const getCustomerTagId = item => {
      let id
      switch (item.type) {
        case 'customerTag':
          // eslint-disable-next-line prefer-destructuring
          id = item.id
          break

        default:
          id = item.customerTagId
          break
      }

      return Number(id)
    }

    const update = async attributes => {
      const res = await TableApi.updateTable({
        tableId: props.tableId,
        params: {
          customerTableTaggingsAttributes: attributes,
        },
      })

      if (res?.data?.data) return res.data.data

      return res
    }

    const select = async items => {
      const newCustomerTags = filter(items, obj => obj.type === 'customerTag')

      each(newCustomerTags, async customerTag => {
        const addTarget = buildCustomerTableTaggingAttributes({ customerTagId: customerTag.id })

        if (addTarget.tableId && props.updateEveryTime) {
          const data = await update([addTarget])

          const id = find((data?.attributes?.customerTableTaggings?.data || []), o => Number(o.attributes.customerTagId) === Number(customerTag.id))?.id
          if (id) addTarget.id = id
          else return

          if (data) emit('updated:table', data)
        }

        customerTableTaggingsAttributes.value.push(addTarget)
      })

      emit('updated', customerTableTaggingsAttributes.value)
    }

    const remove = async item => {
      const removeTargetIdx = findIndex(
        customerTableTaggingsAttributes.value,
        obj => Number(obj.customerTagId) === Number(item.id),
      )
      const removeTarget = customerTableTaggingsAttributes.value[removeTargetIdx]

      if (removeTarget?.id) {
        // eslint-disable-next-line no-underscore-dangle
        removeTarget._destroy = !removeTarget._destroy
      } else {
        customerTableTaggingsAttributes.value.splice(removeTargetIdx, 1)
      }

      if (removeTarget?.id && props.updateEveryTime) {
        const data = await update([removeTarget])
        if (data) emit('updated:table', data)
        customerTableTaggingsAttributes.value.splice(removeTargetIdx, 1)
      }

      emit('updated', customerTableTaggingsAttributes.value)
    }

    const getCustomerTags = async () => {
      isLoading.value = true

      const res = await CustomerTagApi.getCustomerTags()

      if (res?.data) {
        customerTags.value = [...res.data.customerTags.data]
      }

      isLoading.value = false
    }

    const init = customerTableTaggings => {
      customerTableTaggingsAttributes.value = []

      if (customerTableTaggings.length > 0) {
        each(customerTableTaggings, obj => {
          const { customerTagId, tableId } = obj.attributes

          customerTableTaggingsAttributes.value.push(buildCustomerTableTaggingAttributes({
            id: obj.id,
            customerTagId,
            tableId,
          }))
        })
      }
    }

    getCustomerTags()

    init(props.customerTableTaggings)
    emit('init:init-func', init)

    return {
      // data
      isLoading,
      customerTags,
      customerTableTaggingsAttributes,

      // computed
      customerTagIdsToDestroy,

      // methods
      getCustomerTagId,
      select,
      remove,
      isToDestroy,

      icons: {
        mdiPound,
      },
    }
  },
}
</script>

<template>
  <v-select
    v-model="itemId"
    :items="items"
    item-text="attributes.name"
    item-disabled="header"
    item-value="id"
    :menu-props="{ auto: true, offsetY: true }"
    :prepend-inner-icon="prependInnerIcon"
    hide-details
    hide-selected
    clearable
    no-data-text="選択可能なデータがありません"
    @change="onSelect"
    @click:clear="onClear"
  />
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { mdiSelection } from '@mdi/js'
import useQueryString from '@/views/composable/useQueryString'

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemIdKeyName: {
      type: String,
      default: 'id',
    },
    prependInnerIcon: {
      type: String,
      default: () => mdiSelection,
    },
  },
  setup(props, { emit }) {
    const { route, addQuery, removeQuery } = useQueryString()

    const itemId = ref(route.value.query[props.itemIdKeyName])

    const query = computed(() => {
      const obj = {}
      obj[props.itemIdKeyName] = itemId.value

      return obj
    })

    const onSelect = () => {
      addQuery(query.value)

      emit('selected', query.value)
    }

    const onClear = () => {
      itemId.value = null

      removeQuery(props.itemIdKeyName)

      emit('cleared')
    }

    return {
      // data
      itemId,

      // methods
      onSelect,
      onClear,

      icons: {
        mdiSelection,
      },
    }
  },
}
</script>

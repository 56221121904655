<template>
  <v-switch
    v-model="bool"
    :label="label"
    :color="color"
    :true-value="'true'"
    :false-value="'false'"
    hide-details
    @change="onSwitch"
  ></v-switch>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import useQueryString from '@/views/composable/useQueryString'

export default {
  props: {
    boolKeyName: {
      type: String,
      default: 'id',
    },
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  setup(props, { emit }) {
    const { route, addQuery } = useQueryString()

    const bool = ref(route.value.query[props.boolKeyName])

    const query = computed(() => {
      const obj = {}
      obj[props.boolKeyName] = bool.value

      return obj
    })

    const onSwitch = () => {
      addQuery(query.value)

      emit('switched', query.value)
    }

    return {
      // data
      bool,

      // methods
      onSwitch,
    }
  },
}
</script>

<template>
  <div class="pb-15 hr-management-kpi">
    <v-tabs
      v-model="currentTabIdx"
      show-arrows
      style="background: transparent"
    >
      <v-tab
        v-for="role in roles"
        :key="`tab[${role.value}]`"
      >
        {{ role.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentTabIdx"
      touchless
      style="background: transparent"
    >
      <v-tab-item
        v-for="role in roles"
        :key="`tabItems[${role.value}]`"
      >
        <!-- eslint-disable vue/no-deprecated-v-bind-sync -->
        <v-data-table
          id="kpi-table"
          :key="`${headers.map(o => o.value).join('')}${JSON.stringify(items)}`"
          ref="dataTable"
          :expanded.sync="expanded"
          :loading="isLoading"
          :items="items"
          :headers="headers"
          :header-props="{ sortByText: 'ソート' }"
          item-key="userId"
          class="text-no-wrap"
          hide-default-footer
          disable-pagination
          no-data-text="データがありません"
          sort-by="userId"
          :show-expand="!isDaily"
          :fixed-header="!isInViewport"
          :height="isInViewport ? 'auto' : '70vh'"
        >
          <template #loading>
            <v-skeleton-loader type="table" />
          </template>

          <template #top>
            <div class="w-full d-flex">
              <v-spacer />
              <v-btn
                :loading="isLoading"
                :disabled="items.length <= 0 || isLoading"
                :ripple="false"
                color="secondary"
                small
                class="ma-2"
                @click="download"
              >
                <v-icon left>
                  {{ icons.mdiFileExcel }}
                </v-icon>
                ダウンロード
              </v-btn>
            </div>
          </template>

          <template #[`body.prepend`]="{headers: _headers}">
            <tr>
              <td
                v-for="header in _headers"
                :key="`${header.value}-sum`"
                :class="{
                  'fixed-cell': header.cellClass === 'fixed-cell',
                  'v-data-table__divider': header.divider,
                }"
              >
                <span :class="{ 'error--text': Number(accRow[header.text]) && Number(accRow[header.text]) < 0 }">
                  <span v-if="header.unit === '¥'">
                    {{ `¥${Math.abs(accRow[header.text]).toLocaleString()}` }}
                  </span>
                  <span v-else-if="header.unit === '%'">
                    {{ `${Math.abs(accRow[header.text]).toLocaleString()}%` }}
                  </span>
                  <span v-else-if="header.unit === 'pt'">
                    {{ `${Math.abs(accRow[header.text]).toLocaleString()}pt` }}
                  </span>
                  <span v-else>
                    {{ Number(accRow[header.text]) ? `${Math.abs(accRow[header.text]).toLocaleString()}` : accRow[header.text] }}
                  </span>
                </span>
              </td>
            </tr>
          </template>

          <template
            v-for="salesHeader in (userSalesSummaryHeadersByRole[currentRole] || [])"
            #[`item.${salesHeader.value}`]="{item}"
          >
            {{ item[salesHeader.value] ? `¥${item[salesHeader.value].toLocaleString()}` : null }}
          </template>

          <template
            v-for="contributionRatioHeader in (userContributionRatioSummaryHeadersByRole[currentRole] || [])"
            #[`item.${contributionRatioHeader.value}`]="{item}"
          >
            {{ item[contributionRatioHeader.value] ? `${item[contributionRatioHeader.value]}%` : null }}
          </template>

          <template
            v-for="vendingPointHeader in (userVendingPointSummaryHeadersByRole[currentRole] || [])"
            #[`item.${vendingPointHeader.value}`]="{item}"
          >
            {{ item[vendingPointHeader.value] ? `${item[vendingPointHeader.value]}pt` : null }}
          </template>

          <template #expanded-item="{item}">
            <v-dialog
              v-if="expanded.length > 0"
              :value="expanded[0].userId === item.userId"
              transition="dialog-bottom-transition"
              fullscreen
              persistent
            >
              <v-card
                tile
                class="h-full"
              >
                <v-app-bar>
                  <v-app-bar-title>
                    {{ item.userName }}
                    <span class="mr-4">日次</span>
                  </v-app-bar-title>
                  <v-spacer />
                  <v-app-bar-nav-icon>
                    <v-btn
                      fab
                      icon
                      large
                      :ripple="false"
                      @click="expanded = []"
                    >
                      <v-icon>
                        {{ icons.mdiWindowClose }}
                      </v-icon>
                    </v-btn>
                  </v-app-bar-nav-icon>
                </v-app-bar>

                <v-card-text class="pt-4">
                  <user-daily-list
                    v-if="!isDaily"
                    :date="date"
                    :interval="interval"
                    :end-date="endDate"
                    :user-id="+item.userId"
                    :user-name="item.userName"
                    :user-sales-summary-headers="userSalesSummaryHeadersByRole[currentRole]"
                    :user-contribution-ratio-summary-headers="userContributionRatioSummaryHeadersByRole[currentRole]"
                    :user-vending-summary-headers="userVendingSummaryHeadersByRole[currentRole]"
                    :user-referring-summary-headers="userReferringSummaryHeadersByRole[currentRole]"
                    :user-vending-point-summary-headers="userVendingPointSummaryHeadersByRole[currentRole]"
                    :target-time-text="targetTimeText"
                  />
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar
      :value="isLoading"
      centered
      :timeout="-1"
      transition="fade-transition"
      content-class="d-flex justify-center"
    >
      <transition name="fade">
        <span :key="loadingMessages[loadingMessageIdx]">
          {{ loadingMessages[loadingMessageIdx] }}
        </span>
      </transition>
    </v-snackbar>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { mdiFileExcel, mdiWindowClose } from '@mdi/js'
import * as XLSX from 'xlsx/xlsx.mjs'
import UserSalesSummaryApi from '@/api/admin/UserSalesSummary'
import UserContributionRatioSummaryApi from '@/api/admin/UserContributionRatioSummary'
import UserVendingSummaryApi from '@/api/admin/UserVendingSummary'
import UserReferringSummaryApi from '@/api/admin/UserReferringSummary'
import UserVendingPointSummaryApi from '@/api/admin/UserVendingPointSummary'
import useCompInit from '@/views/composable/useCompInit'
import useKpiDataTable from '@/views/composable/data-table/useKpiDataTable'
import UserDailyList from '@/views/components/hr/kpi/UserDailyList.vue'

export default {
  components: {
    UserDailyList,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
    endDate: {
      type: String,
      default: '',
    },
    targetTimeText: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const { isLoading, initWith } = useCompInit()
    const isInViewport = ref(true)
    const dataTable = ref()
    const currentTabIdx = ref(0)
    const userSalesSummaries = ref([])
    const userContributionRatioSummaries = ref([])
    const userVendingSummaries = ref([])
    const userReferringSummaries = ref([])
    const userVendingPointSummaries = ref([])
    const userSalesSummaryHeaders = ref([])
    const userContributionRatioSummaryHeaders = ref([])
    const userVendingSummaryHeaders = ref([])
    const userReferringSummaryHeaders = ref([])
    const userVendingPointSummaryHeaders = ref([])
    const expanded = ref([])

    const roles = [
      { text: 'キャスト', value: 'cast' },
      { text: 'ボーイ', value: 'waiter' },
      { text: 'アライアンス', value: 'alliance' },
    ]
    const currentRole = computed(() => roles[currentTabIdx.value].value)

    const {
      items,
      userSalesSummaryHeadersByRole,
      userContributionRatioSummaryHeadersByRole,
      userVendingSummaryHeadersByRole,
      userReferringSummaryHeadersByRole,
      userVendingPointSummaryHeadersByRole,
      headers,
      accRow,
      buildXlsxData,
    } = useKpiDataTable({
      userSalesSummaries,
      userContributionRatioSummaries,
      userVendingSummaries,
      userReferringSummaries,
      userVendingPointSummaries,
      userSalesSummaryHeaders,
      userContributionRatioSummaryHeaders,
      userVendingSummaryHeaders,
      userReferringSummaryHeaders,
      userVendingPointSummaryHeaders,
      role: currentRole,
    })

    const loadingMessageIdx = ref(0)
    const loadingMessages = [
      '集計中...',
      'もう少しです!',
      'あと少し...',
      '集計には1~2分かかることがあります',
      'あと一歩...',
      'あともうわずか...',
      'ほんのちょっと...',
      '集計が長すぎる場合はサポートまでお問い合わせください',
    ]
    const loadingMessageInterval = ref()
    const showLoadingMessage = () => {
      return setInterval(
        () => {
          loadingMessageIdx.value += 1
          if (loadingMessageIdx.value >= loadingMessages.length) loadingMessageIdx.value = 0
        },
        10000,
      )
    }

    const isDaily = computed(() => {
      return +props.interval === 0 && !props.endDate
    })

    const getUserSalesSummaries = async () => {
      const res = await UserSalesSummaryApi.getUserSalesSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        userSalesSummaries.value = [...res.data.userSalesSummaries]
        userSalesSummaryHeaders.value = [...res.data.headers]
      }
    }
    const getUserContributionRatioSummaries = async () => {
      const res = await UserContributionRatioSummaryApi.getUserContributionRatioSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        userContributionRatioSummaries.value = [...res.data.userContributionRatioSummaries]
        userContributionRatioSummaryHeaders.value = [...res.data.headers]
      }
    }
    const getUserVendingSummaries = async () => {
      const res = await UserVendingSummaryApi.getUserVendingSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        userVendingSummaries.value = [...res.data.userVendingSummaries]
        userVendingSummaryHeaders.value = [...res.data.headers]
      }
    }
    const getUserReferringSummaries = async () => {
      const res = await UserReferringSummaryApi.getUserReferringSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        userReferringSummaries.value = [...res.data.userReferringSummaries]
        userReferringSummaryHeaders.value = [...res.data.headers]
      }
    }

    const getUserVendingPointSummaries = async () => {
      const res = await UserVendingPointSummaryApi.getUserVendingPointSummaries({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        userVendingPointSummaries.value = [...res.data.userVendingPointSummaries]
        userVendingPointSummaryHeaders.value = [...res.data.headers]
      }
    }

    const download = () => {
      const data = buildXlsxData()

      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(data)
      XLSX.utils.book_append_sheet(wb, ws, 'KPI')
      XLSX.writeFile(wb, `KPI_${props.targetTimeText}.xlsx`)
    }

    loadingMessageInterval.value = showLoadingMessage()
    initWith([
      getUserSalesSummaries(),
      getUserContributionRatioSummaries(),
      getUserVendingSummaries(),
      getUserReferringSummaries(),
      getUserVendingPointSummaries(),
    ]).then(() => {
      clearInterval(loadingMessageInterval.value)

      isInViewport.value = true

      const rect = (dataTable.value || [])[0]?.$el?.getBoundingClientRect()
      if (!rect) return

      isInViewport.value = (
        rect.top >= 0
          && rect.left >= 0
          && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    })

    return {
      // data
      dataTable,
      isInViewport,
      currentTabIdx,
      isLoading,
      userSalesSummaries,
      userVendingSummaries,
      userContributionRatioSummaries,
      userReferringSummaries,
      userVendingPointSummaries,
      expanded,
      roles,
      loadingMessageIdx,
      loadingMessages,

      // computed
      isDaily,
      currentRole,
      userSalesSummaryHeadersByRole,
      userContributionRatioSummaryHeadersByRole,
      userVendingSummaryHeadersByRole,
      userReferringSummaryHeadersByRole,
      userVendingPointSummaryHeadersByRole,
      headers,
      items,
      accRow,

      // methods
      download,

      icons: {
        mdiFileExcel,
        mdiWindowClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

@include theme--child(v-data-table__expanded__content) using ($material) {
  td {
    background-color: map-deep-get($material, 'background');
  }
}

.v-data-table__expanded__content td {
  width: 100vw;
  vertical-align: top;

  &>div:first-child {
    padding: 12px 0 32px 0;

    &>.col {
      background-color: rgba(0,0,0,.05);
    }
  }
}
</style>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

@include theme--child(hr-management-kpi) using ($material) {
  th.fixed-cell,
  td.fixed-cell {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
    background: map-deep-get($material, 'app-bar');
  }

  th.fixed-cell {
    z-index: 4;
  }

  .v-progress-linear {
    z-index: 5;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-15 hr-management-kpi"},[_c('v-tabs',{staticStyle:{"background":"transparent"},attrs:{"show-arrows":""},model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}},_vm._l((_vm.roles),function(role){return _c('v-tab',{key:("tab[" + (role.value) + "]")},[_vm._v(" "+_vm._s(role.text)+" ")])}),1),_c('v-tabs-items',{staticStyle:{"background":"transparent"},attrs:{"touchless":""},model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}},_vm._l((_vm.roles),function(role){return _c('v-tab-item',{key:("tabItems[" + (role.value) + "]")},[_c('v-data-table',{key:("" + (_vm.headers.map(function (o) { return o.value; }).join('')) + (JSON.stringify(_vm.items))),ref:"dataTable",refInFor:true,staticClass:"text-no-wrap",attrs:{"id":"kpi-table","expanded":_vm.expanded,"loading":_vm.isLoading,"items":_vm.items,"headers":_vm.headers,"header-props":{ sortByText: 'ソート' },"item-key":"userId","hide-default-footer":"","disable-pagination":"","no-data-text":"データがありません","sort-by":"userId","show-expand":!_vm.isDaily,"fixed-header":!_vm.isInViewport,"height":_vm.isInViewport ? 'auto' : '70vh'},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table"}})]},proxy:true},{key:"top",fn:function(){return [_c('div',{staticClass:"w-full d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"loading":_vm.isLoading,"disabled":_vm.items.length <= 0 || _vm.isLoading,"ripple":false,"color":"secondary","small":""},on:{"click":_vm.download}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileExcel)+" ")]),_vm._v(" ダウンロード ")],1)],1)]},proxy:true},{key:"body.prepend",fn:function(ref){
var _headers = ref.headers;
return [_c('tr',_vm._l((_headers),function(header){return _c('td',{key:((header.value) + "-sum"),class:{
                'fixed-cell': header.cellClass === 'fixed-cell',
                'v-data-table__divider': header.divider,
              }},[_c('span',{class:{ 'error--text': Number(_vm.accRow[header.text]) && Number(_vm.accRow[header.text]) < 0 }},[(header.unit === '¥')?_c('span',[_vm._v(" "+_vm._s(("¥" + (Math.abs(_vm.accRow[header.text]).toLocaleString())))+" ")]):(header.unit === '%')?_c('span',[_vm._v(" "+_vm._s(((Math.abs(_vm.accRow[header.text]).toLocaleString()) + "%"))+" ")]):(header.unit === 'pt')?_c('span',[_vm._v(" "+_vm._s(((Math.abs(_vm.accRow[header.text]).toLocaleString()) + "pt"))+" ")]):_c('span',[_vm._v(" "+_vm._s(Number(_vm.accRow[header.text]) ? ("" + (Math.abs(_vm.accRow[header.text]).toLocaleString())) : _vm.accRow[header.text])+" ")])])])}),0)]}},_vm._l(((_vm.userSalesSummaryHeadersByRole[_vm.currentRole] || [])),function(salesHeader){return {key:("item." + (salesHeader.value)),fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item[salesHeader.value] ? ("¥" + (item[salesHeader.value].toLocaleString())) : null)+" ")]}}}),_vm._l(((_vm.userContributionRatioSummaryHeadersByRole[_vm.currentRole] || [])),function(contributionRatioHeader){return {key:("item." + (contributionRatioHeader.value)),fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item[contributionRatioHeader.value] ? ((item[contributionRatioHeader.value]) + "%") : null)+" ")]}}}),_vm._l(((_vm.userVendingPointSummaryHeadersByRole[_vm.currentRole] || [])),function(vendingPointHeader){return {key:("item." + (vendingPointHeader.value)),fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item[vendingPointHeader.value] ? ((item[vendingPointHeader.value]) + "pt") : null)+" ")]}}}),{key:"expanded-item",fn:function(ref){
              var item = ref.item;
return [(_vm.expanded.length > 0)?_c('v-dialog',{attrs:{"value":_vm.expanded[0].userId === item.userId,"transition":"dialog-bottom-transition","fullscreen":"","persistent":""}},[_c('v-card',{staticClass:"h-full",attrs:{"tile":""}},[_c('v-app-bar',[_c('v-app-bar-title',[_vm._v(" "+_vm._s(item.userName)+" "),_c('span',{staticClass:"mr-4"},[_vm._v("日次")])]),_c('v-spacer'),_c('v-app-bar-nav-icon',[_c('v-btn',{attrs:{"fab":"","icon":"","large":"","ripple":false},on:{"click":function($event){_vm.expanded = []}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiWindowClose)+" ")])],1)],1)],1),_c('v-card-text',{staticClass:"pt-4"},[(!_vm.isDaily)?_c('user-daily-list',{attrs:{"date":_vm.date,"interval":_vm.interval,"end-date":_vm.endDate,"user-id":+item.userId,"user-name":item.userName,"user-sales-summary-headers":_vm.userSalesSummaryHeadersByRole[_vm.currentRole],"user-contribution-ratio-summary-headers":_vm.userContributionRatioSummaryHeadersByRole[_vm.currentRole],"user-vending-summary-headers":_vm.userVendingSummaryHeadersByRole[_vm.currentRole],"user-referring-summary-headers":_vm.userReferringSummaryHeadersByRole[_vm.currentRole],"user-vending-point-summary-headers":_vm.userVendingPointSummaryHeadersByRole[_vm.currentRole],"target-time-text":_vm.targetTimeText}}):_vm._e()],1)],1)],1):_vm._e()]}}],null,true)})],1)}),1),_c('v-snackbar',{attrs:{"value":_vm.isLoading,"centered":"","timeout":-1,"transition":"fade-transition","content-class":"d-flex justify-center"}},[_c('transition',{attrs:{"name":"fade"}},[_c('span',{key:_vm.loadingMessages[_vm.loadingMessageIdx]},[_vm._v(" "+_vm._s(_vm.loadingMessages[_vm.loadingMessageIdx])+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-select
    v-model="itemIds"
    :items="items"
    item-text="attributes.name"
    item-disabled="header"
    :item-value="item => Number(item.id)"
    :menu-props="{ auto: true, offsetY: true, closeOnContentClick: true }"
    :prepend-inner-icon="prependInnerIcon"
    :append-outer-icon="appendOuterIcon"
    hide-details
    hide-selected
    clearable
    multiple
    no-data-text="選択可能なデータがありません"
    :placeholder="placeholder"
    @change="onSelect"
    @click:clear="onClear"
  >
    <template #selection="{ attrs, item, selected }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        small
        :close="!!item.id"
        @click:close="onClose(item.id)"
      >
        {{ item.attributes.name }}
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { mdiSelection } from '@mdi/js'
import { chain, map, compact } from 'lodash'
import useQueryString from '@/views/composable/useQueryString'

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemIdKeyName: {
      type: String,
      default: 'id',
    },
    prependInnerIcon: {
      type: String,
      default: () => mdiSelection,
    },
    appendOuterIcon: {
      type: String,
      default: '',
    },
    withQuery: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { route, addQuery, removeQuery } = useQueryString()

    const itemIds = ref(
      chain(route.value.query[props.itemIdKeyName]?.split(',') || [])
        .map(Number)
        .compact()
        .value(),
    )

    const query = computed(() => {
      const obj = {}

      obj[props.itemIdKeyName] = itemIds.value.join(',')

      return obj
    })

    const emitValue = computed(() => {
      return props.withQuery ? query.value : itemIds.value
    })

    const onSelect = () => {
      if (props.withQuery) addQuery(query.value)

      emit('selected', emitValue.value)
    }

    const onClose = itemId => {
      itemIds.value.splice(itemIds.value.indexOf(Number(itemId)), 1)

      onSelect()
    }

    const onClear = () => {
      itemIds.value = []

      if (props.withQuery) removeQuery(props.itemIdKeyName)

      emit('cleared')
    }

    return {
      // data
      itemIds,

      // methods
      onSelect,
      onClose,
      onClear,

      icons: {
        mdiSelection,
      },
    }
  },
}
</script>
